<template>
  <!-- overflow-hidden -->
  <div class="app-home-view position-relative overflow-hidden">
    <b-container :class="containerClass">
      <!-- Секция 1 -->
      <section class="position-relative">
        <img :src="sectionImg1" class="app-section-img-1 d-none d-lg-block position-absolute">
        <b-row>
          <b-col class="col-md-11 col-lg-9">
            <TextBlock 
              v-bind:title="block1.title[LANG_INDEX]"
              v-bind:textAlign="block1.textAlign"
              class="app-text-block-1 mt-4"
            ></TextBlock>
          </b-col>
        </b-row>
        <b-row>
          <b-col class="app-text-block-2 offset-md-2 offset-lg-3 col-lg-9">
            <TextBlock 
              v-bind:title="block2.title[LANG_INDEX]"
              v-bind:textAlign="block2.textAlign"
              class="app-text-block-2 mb-4 my-md-4 my-lg-5"
            ></TextBlock>
          </b-col>
        </b-row> 
      </section>
      <!-- Секция 2 -->      
      <section>
        <b-row>
          <b-col class="px-sm-3">
            <PartnersBlock 
              v-bind:header="clientsHeader[LANG_INDEX]"
              v-bind:headerPos="'left'"
              v-bind:headerSize="'medium'"
              v-bind:itemsList="clients"
              v-bind:imageClass="'mx-1 mx-sm-3'"
              class="mb-4 mb-lg-5 px-4 px-lg-5"
            ></PartnersBlock>
          </b-col>
        </b-row>
      </section>   
      <!-- Секция 3 -->        
      <section class="position-relative">
        <img :src="sectionImg2" class="app-section-img-3 d-none d-lg-block position-absolute">
        <b-row>
          <b-col class="col-xl-9">
            <TextBlock 
              v-bind:subtitle="block3.subtitle[LANG_INDEX]"
              v-bind:text="block3.text[LANG_INDEX]"
              v-bind:textAlign="block3.textAlign"
              class="app-text-block-3 mt-4 mb-4 mb-lg-5"
            ></TextBlock>
          </b-col>
        </b-row>        
      </section>
      <!-- Секция 4 -->
      <section>
        <b-row class="flex-column-reverse flex-md-row position-relative mb-5 mb-lg-5">
          <b-col class="app-block-decore col-md-6 col-xl-4 position-relative">
            <TeamPhoto></TeamPhoto>
          </b-col>
          <b-col class="col-md-6 col-xl-8 position-static d-flex align-items-md-end align-items-xl-start">
            <BlockCollection 
              v-bind:items="infoItems" 
              v-bind:variant="'variant1'"
              v-bind:background="1"
              class="app-mobile-no-radius py-3 px-4 p-xl-5"
            ></BlockCollection>
          </b-col>
        </b-row>
      </section>   
      <!-- Секция 5 -->  
      <section class="mt-5">
        <b-row class="mb-5 mb-sm-0 mb-xl-5">
          <b-col>
            <AwardsCollection class="mt-0 mt-sm-5 mt-xl-0"></AwardsCollection>
          </b-col>
        </b-row>
      </section>
      <!-- Секция 6 -->  
      <section>
        <b-row>
          <b-col>
            <PartnersBlock 
              v-bind:header="partnersHeader[LANG_INDEX]"
              v-bind:headerPos="'right'"
              v-bind:headerSize="'medium'"
              v-bind:itemsList="partners"
              class="mt-3 mt-md-5 mt-xl-0 mb-4 mb-lg-5 px-4 px-lg-5"
              v-bind:imageClass="'mx-1 mx-sm-3'"
            ></PartnersBlock>
          </b-col>
        </b-row>        
      </section>   
    </b-container>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  data(){
    return {
      clientsHeader: [
        'Our clients',
        'Наши клиенты',
      ],      
      partnersHeader: [
        'Partners',
        'Партнеры',
      ],
      containerClass: 'px-4 px-xl-0 position-relative',
      block1: {
        title: [
          'Agency that boosts apps like no one else',
          'Агентство, которое повышает узнаваемость и прибыль вашего приложения, как никто другой'
        ],
        textAlign: ' text-left'        
      },
      block2: {
        title: [
          'We help businesses to find new customers',
          'Мы помогаем компаниям находить правильных пользователей',
        ],
        textAlign: ' text-right'
      },
      block3: {
        subtitle: [
          'Technological mobile marketing agency',
          'Технологическое агентство мобильного маркетинга',
        ], 
        text: [
          '5 years of market expertise helps us dive into the project to develop and implement a comprehensive strategy for you to attract new users',
          '5 лет опыта работы на рынке помогают нам погрузиться в проект по разработке и реализации комплексной стратегии для привлечения новых пользователей',
        ], 
        textAlign: ' text-left'
      },      
      sectionImg1: require('../assets/iPhone14.png'),
      sectionImg2: require('../assets/man.png'),
      clients: [
        require('../assets/media/clients/client1.png'),
        require('../assets/media/clients/client2.png'),
        require('../assets/media/clients/client3.png'),
        require('../assets/media/clients/client4.png'),
        require('../assets/media/clients/client5.png'),
        require('../assets/clients/client6.png'), //картинка странно масштабируется
        require('../assets/media/clients/client7.png'),

        require('../assets/media/clients/client8.png'),

        require('../assets/media/clients/client9.png'),
        
        require('../assets/media/clients/client10.png'),
        require('../assets/media/clients/client11.png'),
        require('../assets/media/clients/client12.png'),
        require('../assets/media/clients/client13.png'), //картинка плохо масштабируется
        require('../assets/media/clients/client144.png'),
        require('../assets/media/clients/client15.png'),
        require('../assets/media/clients/client16.png'),
      ],
      infoItems: [
        {
          titleIcon: require('../assets/infinity.svg'), 
          text: [
            'mobile experts', 
            'бесконечное количество мобильных экспертов', 
          ], 
          specialClass: ' mob-expert'
        },
        {
          title: [
            '100+',
            '100+',
          ],
           text: [
            'clients',
            'клиентов'
           ]
          },
        {
          title: [
            '150+',
            '150+',
          ], 
          text: [
            'partners',
            'партнеров'
          ] 
        },
      ],
      partners: [
        require('../assets/media/partners/partner1.png'),
        require('../assets/media/partners/partner2.png'),
        require('../assets/media/partners/partner3.png'),
        require('../assets/media/partners/partner4.png'),
        require('../assets/media/partners/partner5.png'), //оригинал изображения не дотягивает до 128px
        require('../assets/media/partners/partner6.png'), //оригинал изображения не дотягивает до 128px
        require('../assets/media/partners/partner7.png'),
        require('../assets/media/partners/partner8.png'),
        require('../assets/media/partners/partner9.png'),
        require('../assets/media/partners/partner10.png'), //оригинал изображения не дотягивает до 128px
        require('../assets/media/partners/partner11.png'),
        require('../assets/media/partners/partner12.png'),
      ],
    }
  },
  computed: {
    ...mapGetters(['LANG_INDEX'])
  },
  name: 'HomeView',
  components: {
  }
}
</script>

<style lang="sass">
.app-home-view
  .app-section-img-1
    width: 192px
    height: 262px
    left: -24px
    bottom: -86px
    //хуевая идея, могу обосновать
    // z-index: 1

//костылим  
@media (max-width: 767px)  
  .app-home-view
    .app-text-block-1
      border-radius: 30px 30px 0 0 !important
    .app-text-block-2
      border-radius: 0 0 30px 30px !important
      //сдвигаем текст нижней плашки на ширину картинки
      .app-text-block__text-wrap
        text-align: left !important

@media (min-width: 1024px) 
  .app-home-view       
    .app-section-img-1
      width: 292px  
      height: 399px
      left: unset
      bottom: unset
      top: 0
      right: 0

@media (min-width: 1366px)
  .app-home-view              
    .app-section-img-1
      width: 391px
      height: 534px

.app-section-img-3
    width: 208px
    height: 208px
    right: -30px
    bottom: -60px

@media (min-width: 768px)            
  .app-section-img-3
    width: 350px
    height: 350px
    bottom: unset
    top: 0
    right: -80px
    z-index: 1
  //на планшах это возможно
  // .app-text-block-3
  //   .app-text-block__subtitle, .app-text-block__text
  //     padding-right: 280px

@media (min-width: 1366px)                  
  .app-section-img-3
    right: 0
    top: -78px
  .app-text-block-3
    .app-text-block__subtitle, .app-text-block__text
      padding-right: unset

//инфоблоки     
.app-home-view
  .app-info-block
    width: calc(50% - 12px)

//покостылим
.app-home-view
    .app-info-block.mob-expert
      position: absolute  
      background: #52378D      
@media (max-width: 767px)    
  .app-home-view
    .app-info-block.mob-expert
      right: 20px
      width: 124px
      bottom: -28px      
      .app-info-block__title-icon
        max-width: 80px
  .app-block-collection
    border-radius: 30px 30px 0 0 !important

@media (min-width: 768px)  
  .app-info-block.mob-expert
    bottom: -60px
    left: 192px
    width: 192px

@media (min-width: 1366px)
  .app-home-view
    .app-info-block
      width: calc(33% - 24px)
    .app-info-block.mob-expert
      position: static
      background: rgba(82, 55, 141, 0.50)      

//покостылим
@media (max-width: 767px)                              
  .app-block-decore::before
    content: ''
    position: absolute
    top: 0
    left: 15px
    right: 15px
    height: 50%
    background: rgba(82, 55, 141, 0.30)
  .app-mobile-no-radius
    border-radius: 30px 30px 0 0 !important    



</style>