export default {
    state: {
        lang: 0
    },
    actions: {
        LANG_CHANGE: function(context, lang){
            context.commit('LANG_MUTATION', lang)
        }
    },
    mutations: {
        LANG_MUTATION: function(state, lang){
            state.lang = lang
        }
    },
    getters: {
        LANG_INDEX: function(state){
            return state.lang
        }
    }
}