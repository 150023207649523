export default {
    state: {
        size: ''
    },
    actions: {
        SIZE_CHECK: function (context) {
            let size = ''
            if (screen.width <= 575) {
                size = 1    //'xs'
            } else if (screen.width > 575 && screen.width < 768) {
                size = 2    //'sm'
            } else if (screen.width >= 768 && screen.width < 992) {
                size = 3    //'md'
            } else if (screen.width >= 992 && screen.width < 1200) {
                size = 4    //'lg'
            } else if (screen.width >= 1200 && screen.width < 1360) {
                size = 5    //'xl'
            } else if (screen.width >= 1360 && screen.width < 1920) {
                size = 6    //'xxl'
            } else if (screen.width >= 1920) {
                size = 7    //'xxxl'
            }            
            context.commit('SIZE_FIX', size)
        }
    },
    mutations: {
        SIZE_FIX: function (state, size) {
            state.size = size            
        }
    },
    getters: {
        SIZE_IS: function (state) {
            return state.size
        }
    }
}