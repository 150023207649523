<template>
    <div class="app-mediabuying-view">
        <b-container class="px-4 px-xl-0">
            <!-- секция 1 -->
            <section class="app-section1 position-relative">
                <b-row>
                    <b-col>
                        <TextBlock 
                            v-bind:title="block1.title[LANG_INDEX]"
                            v-bind:subtitle="block1.subtitle[LANG_INDEX]"
                            v-bind:text="block1.text[LANG_INDEX]"
                            v-bind:image="block1.image"
                            v-bind:textAlign="block1.textAlign"
                            class="app-text-block-1 mt-4 mb-1 mb-lg-5 mb-xl-4"
                        ></TextBlock>
                    </b-col>
                </b-row>                
            </section>
            <!-- Секция 2 -->
            <section class="app-section2 position-relative">
                <b-row>
                    <b-col>
                        <PartnersBlock 
                            v-bind:header="sourceHeader[LANG_INDEX]"
                            v-bind:headerPos="'center'"
                            v-bind:headerSize="'medium'"
                            v-bind:itemsList="sources"
                            class="mb-4 mb-lg-5 mt-4 mt-lg-0 px-4 px-lg-5"
                            v-bind:imageClass="'mx-3 app-source-image'"
                        ></PartnersBlock>
                    </b-col>
                </b-row>
            </section>
            <!-- Секция 3 -->            
            <section>
                <AchievementCollection></AchievementCollection>
            </section>
            <!-- Секция 4 -->            
            <section>
                <b-row class="flex-column-reverse flex-md-row position-relative mb-4 mb-lg-5">
                    <b-col>
                        <BlockCollection 
                            v-bind:items="infoItems" 
                            v-bind:variant="'variant2'"
                            class="d-flex flex-column flex-lg-row py-3 px-4 p-xl-5"
                        ></BlockCollection>
                    </b-col>
                    </b-row>
            </section>
        </b-container>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
    data(){
        return{
            sourceHeader: [
                'Our sources',
                'Наши источники'
            ],
            block1: {
                title: [
                    'Media Buying',
                    'Медиа-баинг',
                ],
                subtitle: [
                    'The right decisions for the best results',
                    // 'Правильные решения для достижения наилучших результатов',
                ], 
                text: [
                    'We work only with high-quality traffic sources that bring the maximum benefit to our sources',
                    'Мы используем только высококачественные источники трафика, которые позволяют максимизировать прибыль наших клиентов и принимать правильные решения для достижения наилучших результатов',
                ], 
                textAlign: ' text-left',
                image: require('../assets/graph.png')
            }, 
            sources: [
                require('../assets/media/sources/source1.png'),
                require('../assets/media/sources/source2.png'),
                require('../assets/media/sources/source3.png'),
                require('../assets/media/sources/source4.png'),
                // require('../assets/sources/source5.png'),
                require('../assets/media/sources/source6.png'),
                // require('../assets/sources/source7.png'),
                require('../assets/media/sources/source8.png'),
                require('../assets/media/sources/source9.png'),
                require('../assets/media/sources/source10.png'),
                // require('../assets/media/sources/empty.png'), //заглушка чтобы не рвать сетку
                // require('../assets/sources/source11.png'),
                // require('../assets/sources/source12.png'),
            ],
            sourcesCount: 0,  
            infoItems: [
                {
                    title: [
                        '>15', 
                        '>15', 
                    ], 
                    text: [
                        'traffic sources',
                        'Источников трафика'
                    ],
                },
                {
                    title: [
                        '>100m', 
                        '>100м', 
                    ], 
                    text: [
                        'installations',
                        'Установок'
                    ], 
                },
                {
                    title: [
                        '150+', 
                        '150+', 
                    ], 
                    text: [
                        'partners',
                        'Партнеров'
                    ]
                },
            ],                                 
        }
    },
    computed: {
        ...mapGetters(['LANG_INDEX'])        
    },
    methods: {
        itemsCountCorrect: function(num){
            let emptyItem = require('../assets/media/sources/empty.png');
            //очищаем предыдущие добавлялки
            let max = this.sourcesCount + num
            for (let i=this.sourcesCount; i<max; i++){
                if (this.sources[i]){
                    this.sources.pop()
                }
            }
            //добавляем если надо
            if (screen.width >=1024 && screen.width <1200){
                for (let i=this.sourcesCount; i<max; i++){
                    this.sources.push(emptyItem)
                }
            }
                return true
        }
    },
    beforeMount: function(){
        //фиксируется единожды
        this.sourcesCount = this.sources.length        
        //вызывается разово
        this.itemsCountCorrect(1)
        let context = this
        //вызывается на ресайзе
        window.onresize = function(){
            context.itemsCountCorrect(1)
        }
    }
}
</script>

<style lang="sass">
.app-section1
    z-index: 2
.app-section2
    z-index: 1
@media (min-width: 992px)
    .app-partner-image.app-source-image 
        max-width: 176px
@media (min-width: 768px)        
    .app-mediabuying-view
        .app-partner-image
            max-width: 130px

@media (min-width: 768px)        
    .app-mediabuying-view
        .app-partner-image
            max-width: 166px    

@media (min-width: 1024px)        
    .app-mediabuying-view
        .app-partner-image
            max-width: 224px
@media (min-width: 1366px)        
    .app-source-image.app-partner-image
        max-width: 188px            
        width: 100%
//костыль
@media (max-width: 767px)            
    .app-source-image
        max-width: 142px !important
//костыль
@media (max-width: 413px)          
    .app-source-image
        max-width: 114px !important      
</style>
