<template>
  <div id="app" :class="appContainerClass">
    <!-- украшалки на главно -->
    <div class="app-home-view__background1 position-absolute" :style="backgroundStyle1"></div>
    <div class="app-home-view__background2 position-absolute" :style="backgroundStyle2"></div>
    <div class="app-home-view__background4 position-absolute d-none d-xl-block" :style="backgroundStyle4"></div>
    <!-- украшалки на медиабаинге -->
    <NavLine />

    <transition name="fade">
      <router-view/>
    </transition>      

    <FooterLine/>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
export default {
  data(){
    return {
      //на главной
      backgroundImage1: require('./assets/rectangle1.svg'),
      backgroundImage2: require('./assets/rectangle2.svg'),      
      backgroundImage4: require('./assets/rectangle4.svg'),      
      //на медиабаинге
    }
  },
  computed: {
    ...mapGetters(['IP']),
    appContainerClass: function(){
      return 'app-root-'+this.$route.name + ' position-relative overflow-hidden'
    },
    backgroundStyle1: function(){
      return `background-image:url(${this.backgroundImage1})`
    },
    backgroundStyle2: function(){
      return `background-image:url(${this.backgroundImage2})`
    },      
    backgroundStyle4: function(){
      return `background-image:url(${this.backgroundImage4})`
    }    
  },
  methods: {
    ...mapActions(['IP_FETCH', 'GEO_FETCH', 'SIZE_CHECK'])
  },
  created: function(){
    this.IP_FETCH()
    this.SIZE_CHECK()
    const vueContext = this
    window.addEventListener('resize', function(){
      vueContext.SIZE_CHECK()
    })
  },
  watch: {
    IP: function(){
      this.GEO_FETCH(this.IP)
    }
  }
}
</script>


<style lang="sass">
//фоновые плашки

.app-home-view__background4
  top: 732px
  left: 0
  right: 0
  bottom: 0
  background-repeat: no-repeat

.app-home-view__background1
  top: 480px
  bottom: 0
  right: 0
  left: -140px
  background-repeat: no-repeat
@media (min-width: 768px)
  .app-home-view__background1
    top: 1620px
@media (min-width: 1024px)    
  .app-home-view__background1
      top: 86%

.app-home-view__background2
  top: 96px
  bottom: 0
  right: -155px
  left: 0
  background-repeat: no-repeat  
  background-position: 100% 0

@media (min-width: 1024px)  
  .app-home-view__background2
    top: 66%

@media (min-width: 1366px)   
  .app-home-view__background2   
    right: 0

.app-home-view__background3
  background-repeat: no-repeat
  left: 0
  right: 0
  bottom: 0
  top: 0

</style>

<style>

.fade-leave-active {
  transition: opacity .2s;
}
.fade-enter-active {
  transition: opacity 0.15s ease-in 0.2s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}

</style>