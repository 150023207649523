export default{
    state: {
        ip: '',
        countryCode: ''
    },
    actions: {
        IP_FETCH: async function(context){
            const url = '//zumoads.com/php/ip.php'
            const res = await fetch(url)
            const json = await res.json()
            const ip = json.ip
            context.commit('IP_UPDATE', ip)
        },
        GEO_FETCH: async function(context, ip){
            //https://api.ipstack.com/95.139.160.194/?access_key=f02e46eb8199aeb73bd0e150813d61d3
            const urlBase = '//api.ipstack.com/'
            const url = `${urlBase}${ip}/?access_key=${process.env.VUE_APP_IPSTACK}`
            const res = await fetch(url)
            const json = await res.json()
            const countryCode = json.country_code
            context.commit('GEO_UPDATE', countryCode)
        }
    },
    mutations: {
        IP_UPDATE: function(state, ip){
            state.ip = ip
        },
        GEO_UPDATE: function(state, countryCode){
            state.countryCode = countryCode
        }
    },
    getters: {
        IP: function(state){
            return state.ip
        },
        GEO: function(state){
            return state.countryCode
        }
    }
}