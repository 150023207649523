import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './sass/main.sass'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)

Vue.component('NavLine', () => import('./components/NavLine'))
Vue.component('SectionAndImage', () => import('./components/SectionAndImage'))
Vue.component('TextBlock', () => import('./components/TextBlock'))
Vue.component('SimpleHeader', () => import('./components/SimpleHeader'))
Vue.component('PartnersBlock', () => import('./components/PartnersBlock'))
Vue.component('TeamPhoto', () => import('./components/TeamPhoto'))
Vue.component('BlockCollection', () => import('./components/BlockCollection'))
Vue.component('InfoBlock', () => import('./components/InfoBlock'))
Vue.component('FooterLine', () => import('./components/FooterLine'))
Vue.component('AchievementCollection', () => import('./components/AchievementCollection'))
Vue.component('AchievementBlock', () => import('./components/AchievementBlock'))
Vue.component('TextCollapse', () => import('./components/TextCollapse'))
Vue.component('CollapseCollection', () => import('./components/CollapseCollection'))
Vue.component('NavItemDesktop', () => import('./components/NavItemDesktop'))
Vue.component('FormInput', () => import('./components/FormInput'))
Vue.component('FormCanvas', () => import('./components/FormCanvas'))
Vue.component('FormCheckbox', () => import('./components/FormCheckbox'))
Vue.component('SocialMedia', () => import('./components/SocialMedia'))
Vue.component('AwardsCollection', () => import('./components/AwardsCollection'))

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
