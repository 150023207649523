<template>
    <div class="app-inapp-view overflow-hidden">
        <b-container :class="containerClass">
            <!-- Секция 1 -->
            <section class="position-relative pb-4 pb-lg-0">
                <b-row class="pb-lg-5 position-relative">
                    <b-col class="col-12 col-lg-2 col-xl-3 position-static d-xl-flex justify-content-center align-items-xl-end">
                        <img :src="sectionImg1" class="app-section-img-1 d-none d-lg-block position-absolute">
                    </b-col>
                    <b-col class="col-lg-10 col-xl-9">
                        <TextBlock 
                            v-bind:title="block1.title[LANG_INDEX]"
                            v-bind:subtitle="block1.subtitle[LANG_INDEX]"
                            v-bind:text="block1.text[LANG_INDEX]"
                            v-bind:textAlign="block1.textAlign"
                            class="app-text-block-1 mt-4"
                        ></TextBlock>
                    </b-col>
                </b-row>
            </section>
            <!-- Секция 2 -->
            <section>
                <SimpleHeader v-bind:header="header[LANG_INDEX]" v-bind:size="'medium'" class="text-center mb-3 mb-xl-5"></SimpleHeader>
                <CollapseCollection></CollapseCollection>
            </section>
            <!-- Секция 3 (geo) -->
            <section>
                <b-row>
                    <b-col class="d-flex flex-column">
                        <SimpleHeader v-bind:header="geoHeader[LANG_INDEX]" class="text-center mt-3 mt-lg-5" v-bind:size="'medium'"></SimpleHeader>
                        <div class="app-geo-subtitle text-center">
                            {{ geoSubHeader[LANG_INDEX] }}
                        </div>
                        <img :src="geoImage">
                    </b-col>
                </b-row>
            </section>
            <!-- Секция 4 -->
            <section>
                <b-row>
                    <b-col class="mt-4">
                        <PartnersBlock 
                            v-bind:header="partnersHeader[LANG_INDEX]"
                            v-bind:headerPos="'center'"
                            v-bind:headerSize="'medium'"
                            v-bind:itemsList="partners"
                            class="mb-4 mb-lg-5 px-4 px-lg-5"
                            v-bind:imageClass="'mx-3 app-source-image'"
                        ></PartnersBlock>
                    </b-col>
                </b-row>
            </section>            
        </b-container>
    </div>    
</template>

<script>
import { mapGetters } from 'vuex'
export default {
    data(){
        return {
            partnersHeader: [
                'Our partners',
                'Наши партнеры',
            ],
            block1: {
                title: [
                    'In-app department',
                    'Отдел Ин-апп',
                ],
                subtitle: [
                    'The best decision to promote your app',
                    'Лучшее решение для продвижения приложений',
                ],
                text: [
                    'Increase the number of app users and generate additional income with us. Optimize your advertising budget and explore new perfomance sources',
                    'Увеличивайте базу пользователей и получайте дополнительный доход вместе с нами',
                ],
                textAlign: ' text-right'
            },
            sectionImg1: require('../assets/mobile.png'),
            header: [
                'Explore new traffic sources with us and optimize your spending on your existing ones',
                'Оптимизируйте свой рекламный бюджет и используйте новые, эффективные источники',
            ],
            partners: [
                require('../assets/media/partners2/partner1.png'),
                require('../assets/media/partners2/partner2.svg'),
                require('../assets/media/partners2/partner3.png'),
                require('../assets/media/partners2/partner4.png'),
                require('../assets/media/partners2/partner5.png'),
                require('../assets/media/partners2/partner6.png'),
                require('../assets/media/partners2/partner7.png'),
                require('../assets/media/partners2/partner8.png'),
                require('../assets/media/partners2/partner9.png'),
                require('../assets/media/partners2/partner10.png'),
                require('../assets/partners2/partner11.png'),   //плохо масштабируется
                require('../assets/media/partners2/partner122.png'),
            ], 
            geoHeader: [
                'GEOs',
                'Географии',
            ],
            geoSubHeader: [
                'worldwide',
                'по всему миру',
            ],            
            geoImage: require('../assets/map.png') 
        }
    },
    computed: {
        ...mapGetters(['LANG_INDEX']),
        containerClass: function(){
            return 'px-4 px-xl-0 pb-5 pb-lg-0'
        }
    }
}
</script>

<style lang="sass">
.app-inapp-view
    .app-partner-image
        max-width: 114px
@media (min-width: 414px)        
    .app-inapp-view
        .app-partner-image
            max-width: 140px
@media (min-width: 1023px)        
    .app-inapp-view
        .app-partner-image
            max-width: 178px
@media (min-width: 1366px)        
    .app-inapp-view
        .app-partner-image
            max-width: 224px            
.app-inapp-view
    .app-section-img-1
        width: 100px
.app-inapp-view
    .app-section-img-1
        right: 0
        left: unset
        bottom: -32px
        transform: rotate(6.511deg)
@media (min-width: 1024px)        
    .app-inapp-view
        .app-section-img-1    
            transform: none
            top: 32px
            left: 0
            width: unset
            height: unset
            z-index: 1
@media (min-width: 1366px)                    
    .app-inapp-view
            .app-section-img-1    
                position: static !important
//app-text-block__title                
@media (max-width: 1023px)             
    .app-inapp-view
        .app-text-block__title, .app-text-block__subtitle, .app-text-block__text
            text-align: left !important

@media (min-width: 1366px)                
    .app-collapse-bar
        width: 50%

</style>