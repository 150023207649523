<template>
    <div class="app-cases-view">
        <b-container class="px-4 px-lg-2 px-xl-5 pb-5 pb-lg-0">
            <b-row>
                <b-col class="px-xl-5">
                    <SimpleHeader v-bind:header="header[LANG_INDEX]" v-bind:size="'big'" class="mt-5 mb-4 mb-xl-5"></SimpleHeader>
                    <BlockCollection 
                        v-bind:items="infoItems1" 
                        v-bind:variant="'variant3'"
                        v-bind:background="1"
                        v-bind:brandImage="brandImage1"
                        class="app-brand-collection-1 py-3 px-4 p-xl-5 mb-3 mb-lg-4 align-items-lg-center"
                    ></BlockCollection>                    

                    <BlockCollection 
                        v-bind:items="infoItems2" 
                        v-bind:variant="'variant3'"
                        v-bind:background="1"
                        v-bind:brandImage="brandImage2"
                        class="app-brand-collection-1 py-3 px-4 p-xl-5 mb-3 mb-lg-4 align-items-lg-center flex-row-reverse flex-lg-row"
                    ></BlockCollection>                    

                    <BlockCollection 
                        v-bind:items="infoItems3" 
                        v-bind:variant="'variant4'"
                        v-bind:background="1"
                        v-bind:brandImage="brandImage3"
                        class="app-brand-collection-2 py-3 px-4 p-xl-5 mb-3 mb-lg-4 align-items-lg-center"
                    ></BlockCollection>      
                    
                    <BlockCollection 
                        v-bind:items="infoItems4" 
                        v-bind:variant="'variant4'"
                        v-bind:background="1"
                        v-bind:brandImage="brandImage4"
                        class="app-brand-collection-2 py-3 px-4 p-xl-5 mb-3 mb-lg-4 align-items-lg-center"
                    ></BlockCollection>   
                    
                    <BlockCollection 
                        v-bind:items="infoItems5" 
                        v-bind:variant="'variant3'"
                        v-bind:background="1"
                        v-bind:brandImage="brandImage5"
                        class="app-brand-collection-2 py-3 px-4 p-xl-5 mb-3 mb-lg-4 align-items-lg-center"
                    ></BlockCollection> 

                    <BlockCollection 
                        v-bind:items="infoItems7" 
                        v-bind:variant="'variant3'"
                        v-bind:background="1"
                        v-bind:brandImage="brandImage7"
                        class="app-brand-collection-2 py-3 px-4 p-xl-5 mb-3 mb-lg-4 align-items-lg-center"
                    ></BlockCollection>                    

                    <BlockCollection 
                        v-bind:items="infoItems6" 
                        v-bind:variant="'variant3'"
                        v-bind:background="1"
                        v-bind:brandImage="brandImage6"
                        class="app-brand-collection-2 py-3 px-4 p-xl-5 mb-3 mb-lg-4 align-items-lg-center"
                    ></BlockCollection>

                    <BlockCollection 
                        v-bind:items="infoItems8" 
                        v-bind:variant="'variant4'"
                        v-bind:background="1"
                        v-bind:brandImage="brandImage8"
                        class="app-brand-collection-2 py-3 px-4 p-xl-5 mb-3 mb-lg-4 align-items-lg-center"
                    ></BlockCollection>                    

                </b-col>
            </b-row>
        </b-container>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
    data(){
        return {
            header: [
                'Our cases',
                'Наши кейсы',
            ], 
            brandImage1: require('../assets/brand/brand1.png'),
            infoItems1: [
                {
                    title: [
                        '>50000',
                        '>50000',
                    ], 
                    text: [
                        'downloads/month',
                        'загрузок/месяц',
                    ], 
                },
                {
                    title: [
                        '>6000', 
                        '>6000', 
                    ],
                    text: [
                        'unique orders/month',
                        'уникальных заказов/месяц'
                    ],
                },
                {
                    title: [
                        '0.6%', 
                        '0.6%', 
                    ],
                    text: [
                        'fraud rate',
                        'уровень fraud',
                    ],
                },
            ],
            brandImage2: require('../assets/brand/brand2.png'),            
            infoItems2: [
                {
                    title: [
                        '>15000$', 
                        '>15000$', 
                    ],
                    text: [
                        'first time deposit amount',
                        'сумма первых депозитов'
                    ], 
                },
                {
                    title: [
                        '>12',
                        '>12',
                    ], 
                    text: [
                        'active geos',
                        'активныx гео'
                    ],
                },
                {
                    title: [
                        '70%',
                        '70%',
                    ], 
                    text: [
                        'users with re-deposiits',
                        '70% пользователей с повторными депозитами'
                    ], 
                },
            ],    
            brandImage3: require('../assets/brand/brand3.png'),            
            infoItems3: [
                {title: [
                    '>50000',
                    '>50000',
                ], 
                text: [
                    'registration/month',
                    'регистраций/месяц'
                ],
            },
                {
                    title: [
                        '>10000', 
                        '>10000', 
                    ],
                    text: [
                        'subscriptions/month',
                        'подписок/месяц'
                    ], 
                },
            ],
            brandImage4: require('../assets/brand/brand4.png'),            
            infoItems4: [
                {
                    title: [
                        '>7000', 
                        '>7000', 
                    ], 
                    text: [
                        'purchases/month',
                        'покупок/месяц'
                    ],
                },
                {
                    title: [
                        '>200000$', 
                        '>200000$', 
                    ], 
                    text: [
                        'from purchases',
                        'от покупок'
                    ], 
                },
            ],
            brandImage5: require('../assets/brand/brand5.png'),            
            infoItems5: [
                {
                    title: [
                        '>1500', 
                        '>1500', 
                    ],
                    text: [
                        'deposit/month',
                        'депозитов/месяц'
                    ],
                },
                {
                    title: [
                        '>25%', 
                        '>25%', 
                    ],
                    text: [
                        'CR reg to deposit',
                        'CR reg для внесения депозита'
                    ],
                },
                {
                    title: [
                        '>350$', 
                        '>350$', 
                    ], 
                    text: [
                        'average revenue per user',
                        'средний доход на пользователя'
                    ], 
                },
            ],
            brandImage6: require('../assets/brand/brand6.png'),            
            infoItems6: [
                {
                    title: [
                        '>2300', 
                        '>2300', 
                    ],
                    text: [
                        'deposit/month',
                        'депозит/месяц'
                    ], 
                },
                {
                    title: [
                        '>35%', 
                        '>35%', 
                    ],
                    text: [
                        'CR reg to deposit',
                        'CR из регистрации в депозит'
                    ],
                },
                {
                    title: [
                        '>250$', 
                        '>250$', 
                    ],
                    text: [
                        'average revenue per user',
                        'средний чек на пользователя'
                    ],
                },
            ],                                                        
            brandImage7: require('../assets/brand/brand7.png'),            
            infoItems7: [
                {
                    title: [
                        '28%', 
                        '28%', 
                    ],
                    text: [
                        'Mintegral CR reg to deposit',
                        'Mintegral  CR из регистрации в депозит'
                    ], 
                },
                {
                    title: [
                        '27%', 
                        '27%', 
                    ],
                    text: [
                        'Iron Source CR reg to deposit',
                        'Iron Source CR регистрации в депозит'
                    ],
                },
                {
                    title: [
                        '500FTDs', 
                        '500FTDs', 
                    ],
                    text: [
                        'per month',
                        'в месяц'
                    ]
                },
            ],            
            brandImage8: require('../assets/brand/brand8.png'),            
            infoItems8: [
                {
                    title: [
                        '> 15 000', 
                        '> 15 000', 
                    ], 
                    text: [
                        'orders',
                        'заказов'
                    ] 
                },
                {
                    title: [
                        '> 500 000$', 
                        '> 500 000$', 
                    ],
                    text: [
                        'sales amount',
                        'сумма продаж'
                    ] 
                },
            ],             
        }
    },
    computed: {
        ...mapGetters(['LANG_INDEX']),
    }    
}
</script>

<style lang="sass">
.app-info-block.variant4:not(.mobile-big) 
    .app-info-block__title
        font-size: 24px
@media (min-width: 1024px)
    .app-info-block.variant4
        .app-info-block__title
            font-size: 40px !important
    .app-info-block.variant4
        width: 296px !important
        height: 133px !important
@media (min-width: 1366px)
    .app-info-block.variant4
        width: 298px !important
        height: 146px !important        
        

.app-brand-collection-2
    .app-info-block.mobile-big
        width: 100%

.app-brand-collection-2
    .app-info-block
        width: calc(50% - 8px)  
        height: 116px
    &:nth-child(1)
        border: 1px dotted red
        
.app-brand-collection-1,
.app-brand-collection-2
    .app-info-block
        width: calc(50% - 8px)
        border-radius: 14px
        height: 116px
//промежуточное костыльное
@media (min-width: 768px)            
    .app-brand-collection-1,
    .app-brand-collection-2
        .app-info-block
            .app-info-block__brand-image
                height: 100%
                width: auto

@media (min-width: 1024px)                
    .app-brand-collection-1,
    .app-brand-collection-2
        .app-info-block 
            max-height: unset
            border-radius: 30px
            &.variant3
                width: 190px  
                height: 133px             
            .app-info-block__brand-image
                width: 100%
                height: auto

@media (min-width: 1366px)                                
    .app-brand-collection-1,
    .app-brand-collection-2
        .app-info-block.variant3
            // width: 257px
            // height: 198px
            width: 190px
            height: 146px
</style>