<template>
    <div class="app-cases-view">
        <b-container class="px-4 px-lg-2 px-xl-5 pb-5 pb-lg-0">
            <section>
                <b-row>
                    <b-col>
                        <SimpleHeader v-bind:header="header[LANG_INDEX]" v-bind:size="'medium'" class="text-center my-5"></SimpleHeader>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col class="col-12 col-xl-6">
                        <p class="pr-5 pr-lg-0">{{text[LANG_INDEX]}}</p>
                        <div class="d-flex align-items-center justify-content-between flex-xl-column align-items-xl-start">
                            <a href="mailto:sales@zumoads.com" class="font-weight-bold mb-lg-5">sales@zumoads.com</a>
                            <SocialMedia class="d-lg-none d-xl-flex"></SocialMedia>
                        </div>    
                    </b-col>
                    
                    <b-col class="d-none d-lg-block col-lg-4 d-xl-none">
                        <SocialMedia class="d-none d-lg-flex d-xl-none"></SocialMedia>
                    </b-col>
                    <b-col class="col-lg-8 col-xl-6">
                        <FormCanvas></FormCanvas>
                    </b-col>
                </b-row>
            </section>
        </b-container>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
    data(){
        return {
            header: [
                'Contact us to make profit',
                'Свяжитесь с нами для получения прибыли',
            ],
            text: [
                'If you want to get more information about us, fill out the form to contact us or reach out in any way that’s convenient for you',
                'Если вы хотите получить больше информации о нас, заполните форму, чтобы связаться с нами',
            ],
            iconInstagram: require('../assets/icons/instagram.svg'),
            iconLinkedin: require('../assets/icons/linkedin.svg')
        }
    },
    computed: {
        ...mapGetters(['LANG_INDEX']),
    }    
}
</script>

<style lang="sass" scoped>
//текст
p
    font-size: 18px
@media (min-width: 1024px)    
    p
        // font-size: 24px
//почта
a
    color: #F2F2F2
    font-size: 16px
@media (min-width: 1024px)     
    a   
        font-size: 28px
</style>