import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import CasesView from '../views/CasesView.vue'
import ContactView from '../views/ContactView.vue'
import InAppView from '../views/InAppView.vue'
import MediaBuyingView from '../views/MediaBuyingView.vue'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
  },
  {
    path: '/cases',
    name: 'cases',
    // component: () => import('../views/CasesView.vue'),
    component: CasesView,
  },
  {
    path: '/contact',
    name: 'contact',
    component: ContactView,
  },
  {
    path: '/inapp',
    name: 'inapp',
    component: InAppView,
  },
  {
    path: '/mediabuying',
    name: 'mediabuying',
    component: MediaBuyingView,
  },      
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
